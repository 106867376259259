<template>
  <div>
    <vs-popup class="sm:popup-w-50" title="Tambah Item Dokumen Proyek" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Group 1 *</label>
            <vs-select v-model="data.group1" class="w-full">
              <vs-select-item v-for="(item, index) in ['Dokumen Proyek', 'Dokumen Unit']" :key="index" :value="item" :text="item"/>
            </vs-select>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Group 2 *</label>
            <vs-input class="w-full" v-model="data.group2"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input class="w-full" label-placeholder="Kode - Generate Otomatis" v-model="data.kode" disabled/>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input class="w-full" label-placeholder="Nama *" v-model="data.nama"/>
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

    </vs-popup>
  </div>
</template>

<script>
import ItemDokumenProyekRepository from '@/repositories/master/item-dokumen-proyek-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'

export default {
  name: 'ItemDokumenProyekAdd',
  components: { ValidationErrors },
  props: ['isActive'],
  data () {
    return {
      isLoading: false,
      errors: null,
      data: {}
    }
  },
  methods: {
    save () {
      this.errors = null
      this.isLoading = true

      ItemDokumenProyekRepository.create(this.data)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      Object.assign(this.$data, this.$options.data.call(this))
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
